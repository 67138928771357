import React, { useEffect, useState } from 'react'
import Banner from '../../components/Banner'
import ProductCard from '../../components/products/ProductCard'
import breadsticks_sesame from '../../assets/images/products/breadsticks_sesame.jpg'
import breadsticks_blackseed from '../../assets/images/products/breadsticks_blackseed.jpg'
import breadsticks_anise from '../../assets/images/products/breadsticks_anise.jpg'
import breadsticks_regular from '../../assets/images/products/breadsticks_regular.jpg'
import Fancybox from '../../assets/libraries/FancyBox'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next'

function ProductDetails({ categories }) {
    const { i18n, t } = useTranslation()
    const params = useParams()
    const [product, setProduct] = useState()
    useEffect(() => {
        axios.get(`products/get?id=${params.id}`).then((response) => {
            console.log(response.data)
            if (response.data) {
                setProduct(response.data)
            }
        }).catch(function (error) {
        })
    }, [])
    return (
        <div>
            <Banner multiImage={false} page={t("products")} />
            <section className='grid grid-cols-1 md:grid-cols-12 px-14 py-10 gap-14'>
                <div className='md:col-span-9 grid grid-cols-1 md:grid-cols-2 gap-y-5 md:gap-5 order-2 md:order-1'>
                    <div className="">
                        <Fancybox
                            options={{
                                Carousel: {
                                    infinite: false,
                                },
                            }}
                        >
                            <a data-fancybox="gallery" href={product ? "http://admin.diafati-bakery.com/Recources/Uploads/" + product.imageUrl : breadsticks_sesame}>
                                <img
                                    alt=""
                                    src={product ? "http://admin.diafati-bakery.com/Recources/Uploads/" + product.imageUrl : breadsticks_sesame}
                                />
                            </a>
                        </Fancybox>
                    </div>
                    {product ?
                        <div className="">
                            <div className="">
                                <h4 className='text-2xl text-black font-bold'>{i18n.language == "ar" ? product.arName : product.enName}</h4>
                                <div className="font-bold mt-2">{t("product_desc")}: </div>
                                <div className="">{i18n.language == "ar" ? product.arDescription != null && parse(product.arDescription) : product.enDescription != null && parse(product.enDescription)}</div>
                                <hr className='my-5' />
                                <div className="font-bold"><strong>{t("components")}: </strong></div>
                                <div className="">
                                    <p>{i18n.language == "ar" ? product.arComponents : product.enComponents}</p>
                                </div>
                                <hr className='my-5' />
                                {product.productDetails.length > 0 &&
                                    <>
                                        {product.productDetails.map((item) => (
                                            <div className=""><small>{i18n.language == "ar" ? item.arFieldName : item.enFieldName}</small> <strong>{i18n.language == "ar" ? item.arValue : item.enValue}</strong></div>
                                        ))}
                                        <hr className='my-5' />
                                    </>
                                }
                                <div className=""><strong>{t("product_expiration")}: </strong></div>
                                <div className="">{i18n.language == "ar" ? product.arExpiryDate : product.enExpiryDate}</div>
                            </div>
                        </div>
                        :
                        <div className="">
                            <div className="">
                                <h4 className='text-2xl text-black font-bold'>كعك شامي بالسمسم</h4>
                                <div className="font-bold mt-2">{t("product_desc")}: </div>
                                <div className="">كعك شامي بالسمسم</div>
                                <hr className='my-5' />
                                <div className="font-bold"><strong>{t("components")}: </strong></div>
                                <div className="">
                                    <p>دقيق القمح</p>
                                </div>
                                <hr className='my-5' />
                                <div className=""><small>التعبئة بالقطعة:</small> <strong>1</strong></div>
                                <div className=""><small>وزن القطعة(غ):</small> <strong>26</strong></div>
                                <div className=""><small>تعبئة العلبة:</small> <strong>24</strong></div>
                                <div className=""><small>وزن العلبة القائم(غ):</small> <strong>369</strong></div>
                                <div className=""><small>تعبئة الطرد:</small> <strong>12</strong></div>
                                <div className=""><small>وزن الطرد القائم(كغ):</small> <strong>4.55</strong></div>
                                <div className=""><small>ابعاد الطرد: </small><strong dir="ltr"> 24*24*56</strong></div>
                                <hr className='my-5' />
                                <div className=""><strong>{t("product_expiration")}: </strong></div>
                                <div className="">ستة اشهر من تاريخ الإنتاج</div>
                            </div>
                        </div>
                    }
                    {/* recommended proucts */}
                    <div className="md:col-span-2">
                        <h2 className='text-black font-bolds text-4xl  mb-8'>{t("recommended_products")}</h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
                            <ProductCard image={breadsticks_blackseed} title={"كعك بحبة البركة"} link="../../products/1" />
                            <ProductCard image={breadsticks_anise} title={"كعك يانسون"} link="../../products/1" />
                            <ProductCard image={breadsticks_regular} title={"كعك سادة"} link="../../products/1" />
                        </div>
                    </div>
                </div>
                <div className="md:col-span-3 order-1 md:order-2">
                    <h4 className='text-2xl mb-4 text-black'>{t("product_classification")}</h4>
                    <div className="widget-content">
                        <ul className="blog-cat">
                            {categories ?
                                categories.map((category) => (
                                    <li className='flex justify-between py-4 border-b border-dashed'>
                                        <a>{i18n.language == "ar" ? category.arName : category.enName}</a>
                                        <span></span>
                                    </li>
                                ))
                                :
                                <>
                                    <li className='flex justify-between py-4 border-b border-dashed'>
                                        <a>كعك بسمسم</a>
                                        <span>13</span>
                                    </li>
                                    <li className='flex justify-between py-4 border-b border-dashed'>
                                        <a>كعك بحبة البركة</a>
                                        <span>7</span>
                                    </li>
                                    <li className='flex justify-between py-4 border-b border-dashed'>
                                        <a>كعك سادة</a>
                                        <span>2</span>
                                    </li>
                                    <li className='flex justify-between py-4 border-b border-dashed'>
                                        <a>كعك باليانسون</a>
                                        <span>2</span>
                                    </li>
                                </>
                            }
                        </ul>
                    </div>
                </div>
            </section >


        </div >
    )
}

export default ProductDetails