import React from 'react'
import { HiOutlinePlus } from 'react-icons/hi'
import { Link } from 'react-router-dom'

function ProductCard({ image, company, title, link, animation }) {
    return (
        <Link to={`${link}`} data-aos={animation ? "zoom-in" : ""} data-aos-duration="1500" className="product_card relative">
            <img src={image} alt={title} className='mx-auto' />
            <div className="overlay_product">
                <div className='space-y-2'>
                    <div className='text-base'>{company}</div>
                    <h4 className='text-xl font-semibold'>{title}</h4>
                </div>
                <Link to={`${link}`} className='border-2 rounded-xl py-2 px-2 text-xl hover:bg-gradient-button'>
                    <HiOutlinePlus />
                </Link>
            </div>
        </Link>)
}

export default ProductCard