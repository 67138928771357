import React from 'react'
import EventCard from '../components/home/EventCard'
import Banner from '../components/Banner'
import news_1 from '../assets/images/news/news_main.jpg';
import news_2 from '../assets/images/news/news_sub_3.jpg';
import news_3 from '../assets/images/news/news_sub_5.jpg';
import { useTranslation } from 'react-i18next';
import Pagination from '../components/utils/Pagination';

function News({ setNewsPagenation, pageCount, news }) {
    const { i18n, t } = useTranslation()

    return (
        <div>
            <Banner multiImage={false} page={t("events_and_news")} />
            <div className='px-14 py-10'>
                {news?.length > 0 ? news.map((item) => (
                    <Pagination pageCount={pageCount} items={news} setPagenation={setNewsPagenation} type={"news"} />
                ))
                    :
                    <div className='grid grid-cols-1 md:grid-cols-3 gap-10'>
                        <EventCard image={news_1} title={"تحضيرات معرض غولفود دبي 2024"} content={" معرض غولفورد يعتبر من أكبر المعارض العالمية المختصة بالأغذية..."} id={1} />
                        <EventCard image={news_2} title={"تحضيرات معرض غولفود دبي 2024"} content={" معرض غولفورد يعتبر من أكبر المعارض العالمية المختصة بالأغذية..."} id={1} />
                        <EventCard image={news_3} title={"تحضيرات معرض غولفود دبي 2024"} content={" معرض غولفورد يعتبر من أكبر المعارض العالمية المختصة بالأغذية..."} id={1} />
                    </div>
                }

            </div>
        </div>
    )
}

export default News