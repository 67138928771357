import React from 'react'
import { useTranslation } from 'react-i18next';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { IoIosArrowBack } from 'react-icons/io';
import { Link } from 'react-router-dom';
import banner_img from "../assets/images/banners/banner_img.jpg"
import image_1 from "../assets/images/products/breadsticks_anise.jpg"
import image_2 from "../assets/images/products/breadsticks_blackseed.jpg"
import image_3 from "../assets/images/products/breadsticks_regular.jpg"
import parse from 'html-react-parser';

function Banner({ multiImage, page, banners }) {
    const { i18n, t } = useTranslation()
    console.log(banners)
    return (
        multiImage ?
            <div id='banner_swiper'>
                <Swiper
                    key={`${i18n.language}-swiper`
                    }
                    modules={[Navigation, Pagination]}
                    slidesPerView={1}
                    navigation
                    loop
                    pagination={{ clickable: true }}
                    scrollbar={{ draggable: true }}
                >
                    {banners && banners?.length > 0 ?
                        banners.map((banner) => (
                            <SwiperSlide>
                                {/* <div style={{ backgroundImage: `url(http://admin.diafati-bakery.com/Recources/Uploads/${banner.imageUrl})` }} className='bg-center top-52 bg-cover py-[17rem] text-white'> */}
                                <div className='top-52 py-[11rem] md:py-[17rem] text-white'>
                                    {banner.link != null ?
                                        <a href={banner.link} target='_blank'>
                                            <img src={`http://admin.diafati-bakery.com/Recources/Uploads/${banner.imageUrl}`} className='absolute top-0 w-full h-full' />
                                            <div className={`${i18n.language == "ar" ? banner.arTitle != null || banner.arDescriptions != null ? "bg-black/50" : "" : banner.enTitle != null || banner.enDescriptions != null ? "bg-black/50" : ""} absolute top-0 h-full w-full`}>
                                                <div className='w-3/4 justify-center flex flex-col h-full m-auto '>
                                                    <div className="text-sm mb-5">{i18n.language == "ar" ? banner.arTitle : banner.enTitle}</div>
                                                    <h1 className='text-3xl text-lg-5xl text-center font-semibold mb-5 break-words '>{i18n.language == "ar" ? banner.arDescriptions != null && parse(banner.arDescriptions) : banner.enDescriptions != null && parse(banner.enDescriptions)}</h1>
                                                    {
                                                        banner.hasButton &&
                                                        <a href={banner.imageUrl} target='_blank' className='btn w-fit'>
                                                            {i18n.language == "ar" ? banner.arButton : banner.enButton}
                                                        </a>
                                                    }
                                                </div>
                                            </div>
                                        </a>
                                        :
                                        <>
                                            <img src={`http://admin.diafati-bakery.com/Recources/Uploads/${banner.imageUrl}`} className='absolute top-0 w-full h-full' />
                                            <div className={`${i18n.language == "ar" ? banner.arTitle != null || banner.arDescriptions != null ? "bg-black/50" : "" : banner.enTitle != null || banner.enDescriptions != null ? "bg-black/50" : ""} absolute top-0 h-full w-full`}>
                                                <div className='w-3/4 justify-center flex flex-col h-full m-auto '>
                                                    <div className="text-sm mb-5">{i18n.language == "ar" ? banner.arTitle : banner.enTitle}</div>
                                                    <h1 className='text-3xl text-lg-5xl text-center font-semibold mb-5 break-words '>{i18n.language == "ar" ? banner.arDescriptions != null && parse(banner.arDescriptions) : banner.enDescriptions != null && parse(banner.enDescriptions)}</h1>
                                                    {
                                                        banner.hasButton &&
                                                        <a href={banner.link} target='_blank' className='btn w-fit mx-auto'>
                                                            {i18n.language == "ar" ? banner.arButton : banner.enButton}
                                                        </a>
                                                    }
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                            </SwiperSlide>
                        ))
                        :
                        <>
                            <SwiperSlide>
                                <div style={{ backgroundImage: `url(${image_1})` }} className='bg-center top-52 bg-cover py-[17rem] text-white'>
                                    {/* <div className='w-3/4 flex flex-col m-auto'>
                                        <div className="text-sm mb-5">أهلاً بكم</div>
                                        <h1 className='text-5xl text-center font-semibold mb-5'>شركة ضيافتي للكعك الشامي</h1>
                                    </div> */}
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div style={{ backgroundImage: `url(${image_2})` }} className='bg-center top-52 bg-cover py-[17rem] text-white'>
                                    {/* <div className='w-3/4 flex flex-col m-auto'>
                                        <div className="text-sm mb-5">أهلاً بكم</div>
                                        <h1 className='text-5xl text-center font-semibold mb-5'>شركة ضيافتي للكعك الشامي</h1>
                                    </div> */}
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div style={{ backgroundImage: `url(${image_3})` }} className='bg-center top-52 bg-cover py-[17rem] text-white'>
                                    {/* <div className='w-3/4 flex flex-col m-auto'>
                                        <div className="text-sm mb-5">أهلاً بكم</div>
                                        <h1 className='text-5xl text-center font-semibold mb-5'>شركة ضيافتي للكعك الشامي</h1>
                                    </div> */}
                                </div>
                            </SwiperSlide>
                        </>
                    }

                </Swiper >
            </div>
            :
            <section style={{ backgroundImage: `url(${banner_img})` }} className={`counter-section bg-fixed bg-center bg-cover`}>
                <div className='h-[370px] px-14 flex items-center'>
                    <div className="grid grid-cols-1 sm:grid-cols-2 w-full text-white">
                        <div className="">
                            <div className="text-base text-white mb-3">{t("company_name")} {t("for_levantine_kaak")}</div>
                            <h2 className='text-4xl'>{page}</h2>
                        </div>
                        <ul className="flex items-center text-lg font-medium gap-x-3 justify-end">
                            <li><Link to="/">{t("home")}</Link></li>
                            <li><IoIosArrowBack /></li>
                            <li>{page}</li>
                        </ul>
                    </div>
                </div>
            </section>
    )
}

export default Banner