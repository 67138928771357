import React, { useEffect, useState } from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import './translation/i18n';
import Home from './pages/Home';
import Layout from './components/Layout';
import About from './pages/About';
import News from './pages/News';
import Jobs from './pages/Jobs';
import ContactUs from './pages/ContactUs';
import Products from './pages/Products';
import NewsDetails from './pages/news/NewsDetails';
import ProductDetails from './pages/products/ProductDetails';
import 'aos/dist/aos.css'
import Aos from 'aos';
import axios from 'axios';

function App() {
  axios.defaults.baseURL = "https://admin.diafati-bakery.com/api/";
  const [banners, setBanners] = useState()
  const [newsPage, setNewsPage] = useState()
  const [productPage, setProductPage] = useState()
  const [productPagenation, setProductPagenation] = useState(1)
  const [newsPagenation, setNewsPagenation] = useState(1)
  const [categories, setCategories] = useState()
  const [aboutUsInfo, setAboutUsInfo] = useState()
  useEffect(() => {
    axios.get("banners/getAll").then((response) => {
      if (response.data) {
        setBanners(response.data.items)
      }
    }).catch((error) => {
    })

    axios.get(`Categories/getAll`).then(function (response) {
      if (response.data)
        setCategories(response.data.items)
    }).catch(function (error) {
    })

    axios.get("pages/getAll").then((response) => {
      if (response.data) {
        let items = response.data.items;
        for (let i = 0; i < items.length; i++) {
          if (items[i].enTitle.toLowerCase().trim() == "about us") {
            setAboutUsInfo(items[i])
          }
        }
      }
    }).catch((error) => {
    })
  }, [])

  useEffect(() => {
    axios.get(`products/getAll?page=${productPagenation}`).then((response) => {
      if (response.data) {
        setProductPage(response.data)
      }
    }).catch((error) => {
    })
  }, [productPagenation])
  useEffect(() => {
    axios.get(`news/getAll?page=${newsPagenation}`).then((response) => {
      if (response.data) {
        setNewsPage(response.data)
      }
    }).catch((error) => {
    })
  }, [newsPagenation])

  useEffect(() => {
    Aos.init();
  }, [])
  return (
    <div>
      <Router>
        <Routes>
          <Route element={<Layout />}>
            <Route path="/" element={<Home banners={banners} news={newsPage?.items} products={productPage?.items} />} />
            <Route path="/about" element={<About info={aboutUsInfo} />} />
            <Route path="/news" element={<News setProductPagenation={setProductPagenation} pageCount={newsPage?.totalPages} news={newsPage?.items} />} />
            <Route path="/news/:id" element={<NewsDetails />} />
            <Route path="/jobs" element={<Jobs />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/products" element={<Products setNewsPagenation={setNewsPagenation} pageCount={productPage?.totalPages} products={productPage?.items} categories={categories} />} />
            <Route path="/products/:id" element={<ProductDetails categories={categories} />} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;