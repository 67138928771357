import React from 'react'
import AboutUs from '../components/home/AboutUs'
import Banner from '../components/Banner'
import { useTranslation } from 'react-i18next'

function About({ info }) {
    const { i18n, t } = useTranslation()

    return (
        <div>
            <Banner multiImage={false} page={t("about_us")} />
            <AboutUs pageInfo={info} withButton={false} />
        </div>
    )
}

export default About