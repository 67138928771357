import React from 'react'
import Banner from '../components/Banner'

function Jobs() {
    return (
        <div>
            <Banner multiImage={false} page={"التوظيف"} />
            <div className='px-14 py-10 grid grid-cols-1 md:grid-cols-12 gap-5'>
                <div className="md:col-span-8 space-y-5">
                    <h3 className="text-3xl">نموذج طلب توظيف</h3>
                    <form className="space-y-5" >
                        <div className="">
                            <label className='text-sm' for="job">الوظيفة المطلوبة <small>*</small></label>
                            <select name="job" id="job" className="form-control" required="">
                                <option value="" selected="selected">تحديد</option>
                            </select>
                        </div>
                        <div className="grid grid-cols-2 gap-5">
                            <div className='space-y-2'>
                                <label className='text-sm' for="fname">الاسم <small>*</small></label>
                                <input name="fname" type="text" className="form-control" id="fname" required="" />
                            </div>
                            <div className='space-y-2'>
                                <label className='text-sm' for="lname">الكنية  <small>*</small></label>
                                <input name="lname" type="text" className="form-control" id="lname" required="" />
                            </div>
                            <div className='space-y-2'>
                                <label className='text-sm' for="birth_date">تاريخ الميلاد:</label>
                                <input type="text" name="birth_date" id="birth_date" className="form-control" />
                            </div>
                            <div className='space-y-2'>
                                <label className='text-sm' for="birth_place">عنوان السكن:</label>
                                <input type="text" name="birth_place" id="birth_place" className="form-control" />
                            </div>
                            <div className='space-y-2'>
                                <label className='text-sm' for="gender">الجنس:</label>
                                <select name="gender" id="gender" className="form-control">
                                    <option value="0">تحديد</option>
                                    <option value="1">ذكر</option>
                                    <option value="2">أنثى</option>
                                </select>
                            </div>
                            <div className='space-y-2'>
                                <label className='text-sm' for="nationality">الجنسية:</label>
                                <input type="text" name="nationality" id="nationality" className="form-control" />
                            </div>
                            <div className='space-y-2'>
                                <label className='text-sm' for="phone">رقم الهاتف / الموبايل:</label>
                                <input name="phone" type="text" required="required" className="form-control" id="phone" />
                            </div>
                            <div className='space-y-2'>
                                <label className='text-sm' for="email">البريد الإلكتروني:</label>
                                <input name="email" type="email" required="required" className="form-control" id="email" />
                            </div>
                            <div className='space-y-2'>
                                <label className='text-sm' for="marital_status">الحالة الاجتماعية:</label>
                                <select name="marital_status" id="marital_status" className="form-control">
                                    <option value="0">تحديد</option>
                                    <option value="1">عزباء/اعزب</option><option value="2">متزوجة/متزوج</option>              </select>
                            </div>
                            <div className='space-y-2'>
                                <label className='text-sm' for="military_service">خدمة العلم:</label>
                                <select name="military_service" id="military_service" className="form-control">
                                    <option value="0">تحديد</option>
                                    <option value="1">منهاة</option><option value="2">غير منهاة</option><option value="3">وحيد</option><option value="4">معفى</option>                        </select>
                            </div>
                            <div className='space-y-2 col-span-2 md:col-span-1'>
                                <label className='text-xs text-red-600' for="file1">أرفق سيرتك الذاتية <span>ملف word أو pdf لا يتجاوز حجمه 8 ميغا</span></label>
                                <input type="file" name="file1" id="file1" className="form-control" />
                            </div>
                        </div>
                        <div>
                            <input type="hidden" name="csrf" value="044d57b5fde9ae417e435ba6a8b39a5bf7ab13772c7dc135563f1f95f10419df" />
                            <input name="submit" type="submit" id="submit" className="btn btn-success" value="إرسال" />
                        </div>
                    </form>
                </div>
                <div className="md:col-span-4 h-fit border-t-2 border-t-[#00908d] bg-[url(https://makki-int.com/images/background/pattern-13.jpg)] p-6 space-y-5">
                    <div className="text-black text-2xl">
                        <h4>الوظائف المتاحة</h4>
                    </div>
                    <div className="px-5 py-6 bg-white text-[#dc3545]">
                        <p className="">عذراً، لا توجد وظائف متاحة في الوقت الراهن.</p>
                    </div>
                </div>
            </div >
        </div >
    )
}

export default Jobs