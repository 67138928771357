import React, { useEffect, useState } from 'react'
import Banner from '../components/Banner'
import { MdEmail, MdOutlineLocationOn } from 'react-icons/md'
import { FaLocationDot } from "react-icons/fa6";
import { FaFax, FaMobileAlt, FaPhone } from 'react-icons/fa';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

function ContactUs() {
    const { i18n, t } = useTranslation()
    const [info, setInfo] = useState()

    useEffect(() => {
        axios.get(`SettingInfos/getSettingInfos`).then(function (response) {
            if (response.data)
                setInfo(response.data)
        }).catch(function (error) {
        })
    }, [])

    return (
        <div>
            <Banner multiImage={false} page={t("contact_us")} />
            <section className=" px-14 py-10">
                <div className="space-y-3">
                    <div className="text-[#00908d] text-lg">{t("keep_in_touch")}</div>
                    <h2 className='font-bold text-black text-4xl'>{t("contact_information")}</h2>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-12 gap-y-5 mt-8">
                    <div className="col-span-5">
                        <ul className="space-y-3 ">
                            <li className='flex gap-5 items-center'>
                                <div className='bg-[#00908d] h-12 w-12'>
                                    <FaLocationDot className='text-lg text-white m-auto h-full' />
                                </div>
                                <div className='space-y-1'>
                                    <span className='text-black font-bold text-xl'>{t("address")}:</span>
                                    <p>{info ? i18n.language == "ar" ? info.arLocation != null ? info.arLocation : "سوريا - دمشق" : info.enLocation ? info.enLocation : "Syria - Damascus" : "سوريا - دمشق"}</p>
                                </div>
                            </li>
                            <li className='flex gap-5 items-center'>
                                <div className='bg-[#00908d] h-12 w-12'>
                                    <FaPhone className='text-lg text-white m-auto h-full' />
                                </div>
                                <div className='space-y-1'>
                                    <span className='text-black font-bold text-xl'>{t("phone")}:</span>
                                    <p dir='ltr'>{info?.phone ? info.phone : "+963 11 000 0000"}</p>
                                </div>
                            </li>
                            {/* <li className='flex gap-5 items-center'>
                                <div className='bg-[#00908d] h-12 w-12'>
                                    <FaMobileAlt className='text-sm text-white m-auto h-full' />
                                </div>
                                <div className='space-y-1'>
                                    <span className='text-black font-bold text-xl'>{t("mobile")}:</span>
                                    <p dir='ltr'>{info ? info.phone : "+963 11 000 0000"}</p>
                                </div>
                            </li> */}
                            <li className='flex gap-5 items-center'>
                                <div className='bg-[#00908d] h-12 w-12'>
                                    <FaFax className='text-xl text-white m-auto h-full' />
                                </div>
                                <div className='space-y-1'>
                                    <span className='text-black font-bold text-xl'>{t("fax")}:</span>
                                    <p dir='ltr'>{info?.fax ? info.fax : "+963 11 000 0000"}</p>
                                </div>

                            </li>
                            <li className='flex gap-5 items-center'>
                                <div className='bg-[#00908d] h-12 w-12'>
                                    <MdEmail className='text-xl text-white m-auto h-full' />
                                </div>
                                <div className='space-y-1'>
                                    <span className='text-black font-bold text-xl'>{t("email")}</span>
                                    <p>{info?.email ? info.email : "+963 11 000 0000"}</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="col-span-7">
                        <form className='h-full'>
                            <div className="flex flex-col gap-5 h-full">
                                <div className='grid grid-cols-2 gap-5'>
                                    <input className='input' type="text" name="username" placeholder={`${t("name")} *`} required />
                                    <input className='input' type="email" name="email" placeholder={`${t("email")} *`} required />
                                </div>
                                <div >
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={`<p>${t("message")}</p>`}
                                        onReady={(editor) => {
                                            // You can store the "editor" and use when it is needed.
                                            editor.editing.view.change((writer) => {
                                                writer.setStyle(
                                                    "height",
                                                    "200px",
                                                    editor.editing.view.document.getRoot()
                                                );
                                            });
                                        }}
                                        onChange={(event) => {
                                        }}
                                        onBlur={(event, editor) => {
                                        }}
                                        onFocus={(event, editor) => {
                                        }}
                                    />
                                </div>
                                <button className="btn w-fit mx-auto">{t("send")}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default ContactUs