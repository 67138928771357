import React, { useEffect, useRef, useState } from 'react'
import { FaSearch, FaTwitter } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { FaFacebookF } from "react-icons/fa";
import { PiListFill } from "react-icons/pi";
import { MdKeyboardDoubleArrowRight, MdKeyboardDoubleArrowLeft } from "react-icons/md";
import { FaXTwitter } from "react-icons/fa6";
import { IoCloseOutline } from "react-icons/io5";
import { IoIosArrowUp } from "react-icons/io";
import { useTranslation } from 'react-i18next';
import { Dropdown, Sidebar } from 'flowbite-react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import enlogo from '../assets/images/logos/enLogo.png'
import arlogo from '../assets/images/logos/arLogo.png'
import WavesSVG from '../pages/WavesSVG';
import Wave from 'react-wavify';
function Layout() {
    const { i18n, t } = useTranslation()
    const navRef = useRef();
    const [showSearch, setShowSearch] = useState(null)
    const [showSidebar, setShowSidebar] = useState(null)
    const { pathname } = useLocation();


    useEffect(() => {
        const updateHeaderState = () => {
            const navRefTop = navRef.current.getBoundingClientRect().top;
            // navRefTop < 2 && Math.abs(navRefTop) < Math.abs(navRef.current.getBoundingClientRect().height)
            if (window.scrollY > 300) {
                navRef.current.classList.add("fixed-nav")
                navRef.current.classList.add("w-full")
                navRef.current.classList.remove("absolute")
                navRef.current.classList.remove("md:w-[95%]")
            }
            if (window.scrollY < 300) {
                if (navRef.current.classList.contains("fixed-nav")) {
                    navRef.current.classList.remove("fixed-nav")
                    // navRef.current.classList.remove("w-full")
                    navRef.current.classList.add("absolute")
                    navRef.current.classList.add("md:w-[95%]")
                }
            }
        }
        window.addEventListener('scroll', updateHeaderState);
        updateHeaderState();
        return () => window.removeEventListener('scroll', updateHeaderState);
    }, [])
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);


    const handleChangeLanguage = (lang) => {
        i18n.changeLanguage(lang);
    }
    return (
        <div dir={i18n.language == "ar" ? 'rtl' : 'ltr'} className={`${i18n.language == "ar" ? 'font-arabic' : 'font-english'} ${showSearch == null ? "" : showSearch == true ? "fixed" : ""} ${showSidebar == null ? "" : showSidebar == true ? "fixed" : ""}`}>
            <div className={`${showSearch == null ? "hidden" : showSearch == true ? "animate-searchOverlay" : "animate-searchOverlayReverse"} fixed search_overlay`}>
                <span onClick={() => setShowSearch(false)}>
                    <IoCloseOutline className={`text-5xl text-white ml-auto mr-10 mt-10 ${showSearch == null ? "" : showSearch == true ? "animate-closingBtnFromTop" : "animate-closingBtnFromTopReverse"} translate-y-[-100px]`} />
                </span>
                <button className={`arrowClose_container ${showSearch == null ? "" : showSearch == true ? "animate-arrowClose" : "animate-arrowCloseReverse"}`}>
                    <IoIosArrowUp className='arrowClose' />
                </button>
                <div className={`input_search_container ${showSearch == null ? "" : showSearch == true ? "animate-searchWidth" : "animate-searchWidthReverse"}`}>
                    <div className="w-full h-full relative overflow-hidden flex">
                        <input className="w-full h-full outline-0 text-lg font-extrabold text-black placeholder:text-black" placeholder='Search Here' />
                        <FaSearch className='text-black cursor-pointer m-auto ' />
                    </div>
                </div>
            </div>
            <div className='bg-primary'>
                <div className='flex justify-between py-2 items-center px-14 '>
                    <div className="text-sm text-white font-normal mx-auto md:mx-0"> {t("company_name")} {t("for_levantine_kaak")}</div>
                    <ul className="items-center gap-3 hidden md:flex">
                        <li className="social_container">
                            <a href="#"><FaFacebookF /></a>
                        </li>
                        <li className="social_container">
                            <a href="#"><FaTwitter /></a>
                        </li>
                        <li className="social_container">
                            <a href="#"><AiFillInstagram /></a>
                        </li>
                    </ul>
                </div>
                {/* <WavesSVG /> */}
                <Wave fill='#fffefe'
                    paused={false}
                    style={{ height: '60px', display: "flex" }}
                    options={{
                        height: 1,
                        amplitude: 10,
                        speed: 0.20,
                        points: 3
                    }}
                />
            </div>
            <div className='bg-secondary'>
                <div className='px-14 flex'>
                    <img src={i18n.language == "ar" ? arlogo : enlogo} className='w-[200px] pb-3 mx-auto md:me-0 md:ms-auto' />
                </div>
            </div>
            <div className='relative'>
                <div ref={navRef} className="bg-[rgba(0,0,0,0.45)] w-full py-2 md:py-0  md:w-[95%] flex justify-end absolute top-0 z-[2]">
                    <div className='flex justify-between pe-5 items-center w-[97%]'>
                        <ul className="me-auto flex-wrap hidden md:flex">
                            <li className='nav-link'>
                                <Link to="/">{t("home")}</Link>
                            </li>
                            <li className='nav-link'>
                                <Link to="/about">{t("about_us")}</Link>
                            </li>
                            <li className="nav-link">
                                <Link to="/products">{t("products")}</Link>
                            </li>
                            {/* <li className='nav-link'>
                                <Link to="/">جديدنا</Link>
                            </li> */}
                            {/* <li className='nav-link'>
                                <Link to="/">{t("special_offer")}</Link>
                            </li> */}
                            <li className='nav-link'>
                                <Link to="/news">{t("events_and_news")}</Link>
                            </li>
                            {/* <li className='nav-link'>
                                <Link to="/">الوكلاء</Link>
                            </li>
                            <li className='nav-link'>
                                <a href="./branches/Ar">الفروع</a>
                            </li>
                            <li className='nav-link'>
                                <Link to="/jobs">التوظيف</Link>
                            </li> */}
                            <li className='nav-link'>
                                <Link to="/contact">{t("contact_us")}</Link>
                            </li>
                            <li className='nav-link'>
                                <Dropdown label={t("language")} inline dismissOnClick={true}>
                                    <Dropdown.Item onClick={() => handleChangeLanguage("ar")}>{t("arabic")}</Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleChangeLanguage("en-US")}>{t("english")}</Dropdown.Item>
                                </Dropdown>
                            </li>
                        </ul>
                        <div onClick={() => setShowSidebar(true)}>
                            <PiListFill className='text-3xl text-white md:hidden cursor-pointer' />
                        </div>
                        {/* <div className='rounded-full border-[1px] w-fit h-fit p-2 cursor-pointer' onClick={() => setShowSearch(true)}>
                            <FaSearch className='text-white' />
                        </div> */}
                    </div>
                </div>
                <Outlet />
            </div>

            <div className={`sideBar_Overlay ${showSidebar == null ? "hidden" : showSidebar == true ? "animate-sideBarOverlay" : "animate-sideBarOverlayReverse"} fixed  left-0`}>
                <div className={`sideBar ${showSidebar == null ? "hidden" : showSidebar == true ? "animate-sideBar" : "animate-sideBarReverse"}
                    ${i18n.language == "ar" ? 'left-0' : 'right-0'}
                    absolute flex`}>
                    <div>
                        <a href="./En">
                            <img src={i18n.language == "ar" ? arlogo : enlogo} className='w-[200px] p-5' />
                        </a>
                        <ul>
                            <li className='px-5 py-2 border-b-2 text-base '><Link to="/" onClick={() => setShowSidebar(false)}>{t("home")}</Link></li>
                            <li className='px-5 py-2 border-b-2 text-base '><Link to="/about" onClick={() => setShowSidebar(false)}>{t("about_us")}</Link></li>
                            <li className='px-5 py-2 border-b-2 text-base '><Link to="/products" onClick={() => setShowSidebar(false)}>{t("about_us")}</Link></li>
                            {/* <li className='px-5 py-2 border-b-2 text-base '><Link to="/" onClick={() => setShowSidebar(false)}>جديدنا</Link></li> */}
                            {/* <li className='px-5 py-2 border-b-2 text-base '><Link to="/" onClick={() => setShowSidebar(false)}>{t("special_offer")}</Link></li> */}
                            <li className='px-5 py-2 border-b-2 text-base '><Link to="/news" onClick={() => setShowSidebar(false)}>{t("events_and_news")}</Link></li>
                            {/* <li className='px-5 py-2 border-b-2 text-base '><Link to="/">الوكلاء</Link></li> */}
                            {/* <li className='px-5 py-2 border-b-2 text-base '><Link to="/">الفروع</Link></li> */}
                            {/* <li className='px-5 py-2 border-b-2 text-base '><Link to="/jobs">التوظيف</Link></li> */}
                            <li className='px-5 py-2 border-b-2 text-base ' >
                                <Dropdown label={t("language")} inline dismissOnClick={true}>
                                    <Dropdown.Item onClick={() => handleChangeLanguage("ar")}>{t("arabic")}</Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleChangeLanguage("en-US")}>{t("english")}</Dropdown.Item>
                                </Dropdown>
                            </li>
                        </ul>
                    </div>
                    <span onClick={() => setShowSidebar(false)}>
                        <IoCloseOutline className="text-3xl text-black" />
                    </span>
                </div>
            </div>
            {/* footer */}
            <div className='bg-primary pt-3'>
                <Wave fill='#fffefe'
                    paused={false}
                    style={{ height: '60px', display: "flex" }}
                    options={{
                        height: 5,
                        amplitude: 10,
                        speed: 0.20,
                        points: 2
                    }}
                />
            </div>
            <div className='bg-secondary grid grid-cols-1 md:grid-cols-3  px-14 pb-3 gap-x-5 bg-no-repeat bg-cover'>
                <div className='flex flex-col items-start'>
                    <img src={i18n.language == "ar" ? arlogo : enlogo} className='w-[200px] mb-5' />
                    <p className='text-sm font-normal mb-8'>{t("company_desc")}</p>
                    <Link to="/about" className='btn'>{t("read_more")}</Link>
                </div>
                <div className='col-span-2 pt-5 grid grid-cols-1 md:grid-cols-2 gap-3'>
                    <div className="flex flex-col justify-between">
                        <h4 className="title_footer">{t("useful_links")}</h4>
                        <ul className="space-y-5 text-primary">
                            <li className='flex gap-x-2 items-center'>{i18n.language == "ar" ? <MdKeyboardDoubleArrowLeft /> : <MdKeyboardDoubleArrowRight />}<Link to="/">{t("home")}</Link></li>
                            <li className='flex gap-x-2 items-center'>{i18n.language == "ar" ? <MdKeyboardDoubleArrowLeft /> : <MdKeyboardDoubleArrowRight />}<Link to="/about">{t("about_us")}</Link></li>
                            {/* <li className='flex gap-x-2 items-center'>{i18n.language == "ar" ? <MdKeyboardDoubleArrowLeft /> : <MdKeyboardDoubleArrowRight />}<Link to="/">ماذا يقول عملاؤنا؟</Link></li> */}
                            <li className='flex gap-x-2 items-center'>{i18n.language == "ar" ? <MdKeyboardDoubleArrowLeft /> : <MdKeyboardDoubleArrowRight />}<Link to="/products">{t("products")}</Link></li>
                            <li className='flex gap-x-2 items-center '>{i18n.language == "ar" ? <MdKeyboardDoubleArrowLeft /> : <MdKeyboardDoubleArrowRight />}<Link to="/news">{t("events_and_news")}</Link></li>
                            <li className='flex gap-x-2 items-center '>{i18n.language == "ar" ? <MdKeyboardDoubleArrowLeft /> : <MdKeyboardDoubleArrowRight />}<Link to="/contact">{t("contact_us")}</Link></li>
                            {/* <li className='flex gap-x-2 items-center'>{i18n.language == "ar" ? <MdKeyboardDoubleArrowLeft /> : <MdKeyboardDoubleArrowRight />}<Link to="/">جديدنا</Link></li> */}
                        </ul>
                    </div>
                    <div className='flex flex-col justify-between'>
                        <h4 className="title_footer">{t("follow_us")}</h4>
                        <ul className="items-center gap-3 flex">
                            <li className="social_container">
                                <a href="#"><FaFacebookF /></a>
                            </li>
                            <li className="social_container">
                                <a href="#"><FaXTwitter /></a>
                            </li>
                            <li className="social_container">
                                <a href="#"><AiFillInstagram /></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className='bg-black text-white text-base py-4 px-14'>
                <p>Copyright © 2024</p>
            </div>
        </div >
    )
}

export default Layout