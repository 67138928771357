import React from 'react'
import breadsticks_regular from '../../assets/images/products/breadsticks_regular.jpg'
import breadsticks_sesame from '../../assets/images/products/breadsticks_sesame.jpg'
import breadsticks_blackseed from '../../assets/images/products/breadsticks_blackseed.jpg'
import ProductCard from '../products/ProductCard'
import { useTranslation } from 'react-i18next'
function OurProducts({ products }) {
    const { i18n, t } = useTranslation()

    return (
        <section className="py-10  px-14">
            <div className="text-center pb-10">
                <h2 data-aos="flip-up" data-aos-duration="1500" className='text-4xl text-black font-bold'>{t("our_products")}</h2>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
                {products && products?.length > 0 ?
                    products.slice(0, 3).map((product) => (
                        <ProductCard image={"http://admin.diafati-bakery.com/Recources/Uploads/" + product.imageUrl} company={t("company_name")} title={i18n.language == "ar" ? product.arName : product.enName} link={`./products/${product.id}`} animation={true} />
                    ))
                    :
                    <>
                        <ProductCard image={breadsticks_regular} company={"ضيافاتي للكعك الشامي"} title={"كعك شامي سادة"} link={`./products/1`} animation={true} />
                        <ProductCard image={breadsticks_sesame} company={"ضيافاتي للكعك الشامي"} title={"كعك شامي بسمسم"} link={`./products/1`} animation={true} />
                        <ProductCard image={breadsticks_blackseed} company={"ضيافاتي للكعك الشامي"} title={"كعك شامي بحبة البركة"} link={`./products/1`} animation={true} />
                    </>
                }

            </div>
        </section>
    )
}

export default OurProducts