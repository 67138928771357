import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import ProductCard from '../products/ProductCard';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import EventCard from '../home/EventCard';

// Example items, to simulate fetching from another resources.

function Items({ currentItems, type }) {
  const { i18n, t } = useTranslation()
  return (<>
    {type == "products" ?
      <div className='md:col-span-9 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 order-2 md:order-1'>
        {currentItems &&
          currentItems.map((item) => (
            <ProductCard image={`http://admin.diafati-bakery.com/Recources/Uploads/${item.imageUrl}`} title={i18n.language == "ar" ? item.arName : item.enName} link={`./${item.id}`} animation={true} />
          ))}
      </div>
      : type == "news" ?
        <div className='grid grid-cols-1 md:grid-cols-3 gap-10'>
          {currentItems &&
            currentItems.map((item) => (
              <EventCard image={"http://admin.diafati-bakery.com/Recources/Uploads/" + item.imageUrl} title={i18n.language == "ar" ? item.arTitle : item.enTitle} content={i18n.language == "ar" ? item.arContent != null && parse(item.arContent) : item.enContent != null && parse(item.enContent)} id={item.id} />
            ))}
        </div>
        : ""}
  </>
  );
}

function Pagination({ setPagenation, items, pageCount, type }) {

  const handlePageClick = (event) => {
    setPagenation(event.target.value);
  };

  return (
    <>
      <Items currentItems={items} type={type} />
      <ReactPaginate
        nextLabel=">"
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        pageCount={pageCount}
        previousLabel="<"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active"
        renderOnZeroPageCount={null}
      />
    </>
  );
}

export default Pagination