import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import parse from 'html-react-parser';

function EventCard({ image, title, content, id }) {
    const { i18n, t } = useTranslation()

    return (
        <div data-aos="fade-out" data-aos-duration="1500" className="border-2">
            <img src={image} alt={title} className='w-full' />
            <div className="p-5 space-y-4 text-center">
                <h4 className=' text-black text-xl'>
                    <Link to={`/news/${id}`}>{title}</Link>
                </h4>
                {/* dangerouslySetInnerHTML={{ __html: content }} */}
                <div className="text-base" >
                </div>
                <hr className='mx-auto' />
                <div className="text-base text-start">
                    <Link to={`/news/${id}`} className="hover:text-link">{t("read_more")}</Link>
                </div>
            </div>
        </div>
    )
}

export default EventCard