import React from 'react'
import EventCard from './EventCard'
import news_1 from '../../assets/images/news/news_main.jpg';
import news_2 from '../../assets/images/news/news_sub_3.jpg';
import news_3 from '../../assets/images/news/news_sub_5.jpg';
import { Autoplay, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { useTranslation } from 'react-i18next';

function LastNews({ news }) {
    const { i18n, t } = useTranslation()

    return (
        <section className="py-10  px-14">
            <div className="text-center pb-10">
                <h2 data-aos="flip-up" data-aos-duration="1500" className='text-4xl text-black font-bold'>{t("events_and_news")}</h2>
            </div>

            <Swiper
                key={`${i18n.language}-swiper`}
                modules={[Pagination, Autoplay]}
                slidesPerView={1}
                spaceBetween={50}
                loop={true}
                autoplay={{
                    delay: 5000, // Slide transition duration
                }}
                breakpoints={{
                    640: {
                        slidesPerView: 1,
                    },
                    768: {
                        slidesPerView: 2,
                    },
                    1024: {
                        slidesPerView: 3,
                    },
                }}
                pagination={{ clickable: true }}
                scrollbar={{ draggable: true }}
                className='!pb-10'
            >
                {news && news?.length > 0 ?
                    news.map((card) => (
                        <SwiperSlide>
                            {console.log(card)}
                            <EventCard image={"http://admin.diafati-bakery.com/Recources/Uploads/" + card.imageUrl} title={i18n.language == "ar" ? card.arTitle : card.enTitle} content={i18n.language == "ar" ? card.arContent : card.enContent} id={card.id} />
                        </SwiperSlide>
                    ))
                    :
                    <>
                        <SwiperSlide>
                            <EventCard image={news_1} title={"تحضيرات معرض غولفود دبي 2024"} content={" معرض غولفورد يعتبر من أكبر المعارض العالمية المختصة بالأغذية..."} id={1} />
                        </SwiperSlide>
                        <SwiperSlide>
                            <EventCard image={news_2} title={"تحضيرات معرض غولفود دبي 2024"} content={" معرض غولفورد يعتبر من أكبر المعارض العالمية المختصة بالأغذية..."} id={1} />
                        </SwiperSlide>
                        <SwiperSlide>
                            <EventCard image={news_3} title={"تحضيرات معرض غولفود دبي 2024"} content={" معرض غولفورد يعتبر من أكبر المعارض العالمية المختصة بالأغذية..."} id={1} />
                        </SwiperSlide>
                        <SwiperSlide>
                            <EventCard image={news_1} title={"تحضيرات معرض غولفود دبي 2024"} content={" معرض غولفورد يعتبر من أكبر المعارض العالمية المختصة بالأغذية..."} id={1} />
                        </SwiperSlide>
                        <SwiperSlide>
                            <EventCard image={news_2} title={"تحضيرات معرض غولفود دبي 2024"} content={" معرض غولفورد يعتبر من أكبر المعارض العالمية المختصة بالأغذية..."} id={1} />
                        </SwiperSlide>
                        <SwiperSlide>
                            <EventCard image={news_3} title={"تحضيرات معرض غولفود دبي 2024"} content={" معرض غولفورد يعتبر من أكبر المعارض العالمية المختصة بالأغذية..."} id={1} />
                        </SwiperSlide>
                    </>}

            </Swiper>
        </section>
    )
}

export default LastNews