import React from 'react'
import Banner from '../components/Banner'
import ProductCard from '../components/products/ProductCard'
import breadsticks_sesame from '../assets/images/products/breadsticks_sesame.jpg'
import breadsticks_blackseed from '../assets/images/products/breadsticks_blackseed.jpg'
import breadsticks_anise from '../assets/images/products/breadsticks_anise.jpg'
import breadsticks_regular from '../assets/images/products/breadsticks_regular.jpg'
import { useTranslation } from 'react-i18next'
import Pagination from '../components/utils/Pagination'
function Products({ setProductPagenation, pageCount, products, categories }) {
    const { i18n, t } = useTranslation()

    return (
        <div>
            <Banner multiImage={false} page={t("products")} />
            <section className='grid grid-cols-1 md:grid-cols-12 px-14 py-10 gap-14'>
                <div className='md:col-span-9 order-2 md:order-1'>
                    {products ?
                        <Pagination pageCount={pageCount} items={products} setPagenation={setProductPagenation} type={"products"} />
                        :
                        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 '>
                            <ProductCard image={breadsticks_sesame} title={"كعك بسمسم"} link={`./1`} animation={true} />
                            <ProductCard image={breadsticks_blackseed} title={"كعك بحبة البركة"} link={`./1`} animation={true} />
                            <ProductCard image={breadsticks_anise} title={"كعك يانسون"} link={`./1`} animation={true} />
                            <ProductCard image={breadsticks_regular} title={"كعك سادة"} link={`./1`} animation={true} />
                        </div>
                    }
                </div>
                <div data-aos="fade-right" className="md:col-span-3 order-1 md:order-2">
                    <h4 className='text-2xl mb-4 text-black'>{t("product_classification")}</h4>
                    <div className="widget-content">
                        <ul className="blog-cat">
                            {categories ?
                                categories.map((category) => (
                                    <li className='flex justify-between py-4 border-b border-dashed'>
                                        <span>{i18n.language == "ar" ? category.arName : category.enName}</span>
                                        <span></span>
                                    </li>
                                ))
                                :
                                <>
                                    <li className='flex justify-between py-4 border-b border-dashed'>
                                        <span>كعك بسمسم</span>
                                        <span></span>
                                    </li>
                                    <li className='flex justify-between py-4 border-b border-dashed'>
                                        <span>كعك بحبة البركة</span>
                                        <span></span>
                                    </li>
                                    <li className='flex justify-between py-4 border-b border-dashed'>
                                        <span>كعك سادة</span>
                                        <span></span>
                                    </li>
                                    <li className='flex justify-between py-4 border-b border-dashed'>
                                        <span>كعك باليانسون</span>
                                        <span></span>
                                    </li>
                                </>
                            }

                        </ul>
                    </div>
                </div>
            </section>

        </div>
    )
}

export default Products