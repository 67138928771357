import React, { useEffect, useState } from 'react'
import Banner from '../../components/Banner'
import { FaRegCalendarAlt } from 'react-icons/fa'
import news_title from '../../assets/images/news/news_sub_2.jpg'
import news_sub_1 from '../../assets/images/news/news_sub_1.jpg'
import news_sub_2 from '../../assets/images/news/news_sub_3.jpg'
import news_sub_3 from '../../assets/images/news/news_sub_4.jpg'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next'

function NewsDetails() {
    const { i18n, t } = useTranslation()
    const [details, setDetails] = useState()
    const params = useParams()
    useEffect(() => {
        axios.get(`news/get?id=${params.id}`).then(function (response) {
            console.log(response.data)
            if (response.data)
                setDetails(response.data)
        }).catch(function (error) {
        })
    }, [])
    return (
        <div>
            <Banner multiImage={false} page={t("events_and_news")} />
            <div className="grid grid-cols-1 lg:grid-cols-12 px-14 py-10 gap-5">
                <div className="lg:col-span-8 border">
                    <img src={details ? `http://admin.diafati-bakery.com/Recources/Uploads/${details.imageUrl}` : news_title} alt={details ? i18n.language == "ar" ? details.arTitle : details.enTitle : " تحضيرات معرض غولفود دبي 2024"} className='w-full' />
                    <div className="p-6 space-y-3">
                        <div className='flex items-center gap-2'>{details ? details.publishDate : "March 3, 2024"} <FaRegCalendarAlt /></div>
                        <h4 className='text-lg font-bold text-black'>{details ? i18n.language == "ar" ? details.arTitle : details.enTitle : " تحضيرات معرض غولفود دبي 2024"}</h4>
                        <div className="">
                            {details ?
                                <p>{i18n.language == "ar" ? details.arContent != null && parse(details.arContent) : details.enContent != null && parse(details.enContent)}</p>
                                :
                                <p>يعد معرض غولفود من اكبر المعارض العالمية للاغذنية<br /><br />
                                    حيث تجتمع كل الشركات الكبرى لعرض المنتجات و اتاحة أفضل تجربة للمنتج للزبون<br /><br />
                                    و من المقرر أن يشهد المعرض مشاركة أكثر من 5,500 جهة عارضة، وزواراً من أكثر من 190 دولة، ضمن مساحة عرض تتوزع على 24 قاعة</p>}
                        </div>
                    </div>

                </div>
                <div className="lg:col-span-4 border-t-2 border-[#00908d] bg-[url(https://makki-int.com/images/background/pattern-13.jpg)] p-6 h-fit">
                    <h4 className='mb-4 text-2xl text-black'>{t("last_news")}</h4>
                    <div className="bg-white py-5 px-6">
                        <article className="flex gap-x-4 py-5 border-b">
                            <figure className="post-thumb">
                                <img src={news_sub_1} alt="عنوان خبر 3" />
                            </figure>
                            <div className="space-y-1">
                                <a className='text-black text-md font-bold'>تحضيرات واجهات العرض</a>
                                <div className='flex items-center gap-2'><FaRegCalendarAlt /> March 3, 2024 </div>
                            </div>
                        </article>
                        <article className="flex gap-x-4 py-5 border-b">
                            <figure className="post-thumb">
                                <img src={news_sub_2} alt="عنوان خبر 2" />
                            </figure>
                            <div className="space-y-1">
                                <a className='text-black text-md font-bold'>تحضيرات واجهات المعرض</a>
                                <div className='flex items-center gap-2'><FaRegCalendarAlt /> March 3, 2024 </div>
                            </div>
                        </article>
                        <article className="flex gap-x-4 py-5">
                            <figure className="post-thumb">
                                <img src={news_sub_3} alt="عنوان خبر 1" />
                            </figure>
                            <div className="space-y-1">
                                <a className='text-black text-md font-bold'>تحضيرات واجهات العرض</a>
                                <div className='flex items-center gap-2'><FaRegCalendarAlt /> March 3, 2024 </div>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewsDetails