import React, { useEffect, useState } from 'react'
import AboutUs from '../components/home/AboutUs'
import Banner from '../components/Banner'
import LastNews from '../components/home/LastNews'
import OurProducts from '../components/home/OurProducts'
import Testimonials from '../components/home/Testimonials'
import banner_img from '../assets/images/banners/banner_img.jpg'
import { useTranslation } from 'react-i18next'

function Home({ banners, news, products }) {
    const { i18n, t } = useTranslation()

    return (
        <div>
            <Banner multiImage={true} banners={banners} />
            <AboutUs withButton={true} />
            <section style={{ backgroundImage: `url(${banner_img})` }} className="counter-section bg-fixed bg-center bg-cover">
                <div className=' bg-black bg-opacity-70  py-20'>
                    <div className="mx-auto w-fit text-center  text-white space-y-5">
                        <h2 data-aos="fade-up" data-aos-duration="1500" className='text-4xl font-bold'>{t("company_name")}<br />{t("for_levantine_kaak")}</h2>
                        <div data-aos="fade-up" data-aos-duration="1500" className="text-lg">{t("company_goal_1")}<br />{t("company_goal_2")}</div>
                    </div>
                </div>
            </section>
            <OurProducts products={products} />
            {/* <Testimonials /> */}
            <LastNews news={news} />
        </div>
    )
}

export default Home