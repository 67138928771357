import React from 'react'
import { Link } from 'react-router-dom'
import about_img from '../../assets/images/about_us.jpg'
import { useTranslation } from 'react-i18next'
import parse from 'html-react-parser';

function AboutUs({ pageInfo, image, withButton }) {
    const { i18n, t } = useTranslation()
    return (
        <section className="px-14 py-10">
            <div className="grid grid-cols-1 lg:grid-cols-12 gap-5 h-full">
                <div className="col-span-5 mb-10">
                    <div className="relative mx-auto sm:w-3/4">
                        <img data-aos="fade-left" data-aos-duration="1500" className='' src={about_img} alt="شركة ضيافتي للكعك الشامي" />
                        <div data-aos="fade-left" data-aos-duration="1500" className="py-5 px-12 w-fit md:text-2xl  bg-gradient-label text-white font-bold absolute bottom-0 lg:bottom-[-3.8rem] right-0">
                            {t("company_name")} <br />{t("company_created_date")}
                        </div>
                    </div>
                </div>
                <div className="col-span-7">
                    <div className="space-y-3 mb-5">
                        {/* <div data-aos="flip-up" data-aos-duration="1500" className="text-[#00908d]">أهلاً بكم</div> */}
                        <h2 data-aos="flip-up" data-aos-duration="1500" className='text-4xl font-bold text-black'>{t("company_name")} {t("for_levantine_kaak")}</h2>
                    </div>
                    {
                        pageInfo ?
                            <div data-aos="fade-in" data-aos-duration="1500" className="space-y-3 mb-5 text-base">
                                {i18n.language == "ar" ? pageInfo.arContent && parse(pageInfo.arContent) : pageInfo.enContent && parse(pageInfo.enContent)}
                            </div>
                            :
                            <div data-aos="fade-in" data-aos-duration="1500" className="space-y-3 mb-5 text-base">
                                <p>
                                    شركة  كعك ضيافتي  تأسست عام ٢٠١٥ في دمشق و تعمل في مجال  صناعة  الكعك الشامي بجيمع انواعه
                                </p>
                                <p>
                                    شركة  كعك ضيافتي هو وجهتك المثالي وأشهى أنواع الكعك . نقدم مجموعة كاملة من اصناف الكعك الالية .
                                </p>
                                <p>
                                    خدماتنا:
                                </p>
                                <div className='space-y-2'>
                                    <div className='space-y-1'>
                                        <h2>1. تشكيلة متنوعة من الكعك:</h2>
                                        <p>
                                            نفخر بتقديم تشكيلة واسعة من الكعك، حاضرة من الكعك التقليدي إلى الكعك المبتكر سواء كنت تبحث عن كعك بالسمسم او حبة البركة او اليانسون او الساده  في الحال نضمن لك تجربة مميزة
                                        </p>
                                    </div>
                                    <div className='space-y-1'>
                                        <h2>2. التصدير والشحن:</h2>
                                        <p>
                                            نحن نعمل على تلبية العملاء الأساسيين أيضًا. بحث عن خدمات الشحن والتصدير للكعك إلى مختلف البلدان. سواء كنت ترغب في تقديم هدية فريدة أو تجربة كعكة فاخرة في بلدك، فإنك ستلبي طلباتك بشكل كبير.
                                        </p>
                                    </div>
                                    <div className='space-y-1'>
                                        <h2>3. المشاركة في المعارض التجارية الدولية:</h2>
                                        <p>
                                            نحن نؤمن بأهمية التواجد في المعارض الدولية لتعزيز علامتنا والتواصل مع عملاء جدد. نشارك في المعارض الدولية للحلويات والمأكولات، حيث نعرض تشكيلتنا المتنوعة من الكعك ونجذب حضورنا بجودتنا وابتكارنا
                                        </p>
                                    </div>
                                </div>
                                <p>
                                    <span> في شركة كعك ضيافتي ، نحرص على تقديم تجربة أفضل. نحن نجمع بين الطعم الرائع والجمال الفني لنخلق كعكة فريدة من نوعها تلبي توقعاتكم وتضفي لمسة سحرية على مناسبتكم الخاصة. تفضلوا بزيارتنا اليوم أقدموا بعالم من التنوع والتصاميم الفريدة في معمل الكعك المتخصص.
                                    </span>
                                </p>
                            </div>
                    }

                    {
                        withButton &&
                        <Link to="/about" data-aos="fade-in" data-aos-duration="500">
                            <div className="btn w-fit">
                                {t("read_more")}
                            </div>
                        </Link>
                    }

                </div>
            </div>
        </section>
    )
}

export default AboutUs